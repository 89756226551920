<template>
    <div>
		<el-dialog :title="title" :visible.sync="open" width="500px" @close="commitOnClose">
			<el-form ref="saveOrEditForm" :model="saveData" label-width="120px">
				<el-form-item :label="translate('CONFIG.CODE')" prop="code">
					<el-input v-model="saveData.code" :placeholder="translate('FROM.PLACEHOLDER', {param: 'CONFIG.CODE'})" :disabled="!isAdd"/>
				</el-form-item>
				<el-form-item :label="translate('CONFIG.VALUE')" prop="value">
					<el-input v-model="saveData.value" :placeholder="translate('FROM.PLACEHOLDER', {param: 'CONFIG.VALUE'})"/>
				</el-form-item>
				<el-form-item :label="translate('CONFIG.DESCRIPTION')" prop="description">
					<el-input v-model="saveData.description" :placeholder="translate('FROM.PLACEHOLDER', {param: 'CONFIG.DESCRIPTION'})"/>
				</el-form-item>
				<el-form-item :label="translate('CONFIG.STATUS')" prop="state">
					<el-radio-group v-model="saveData.status">
						<el-radio v-for="(value,key) in dicts.STATUS" :key="key" :label="key">{{value}}</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitForm">{{translate('FROM.SUBMIT')}}</el-button>
				<el-button type="primary" @click="cancel">{{translate('FROM.CANCEL')}}</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {BasicApi} from '@/api';
	export default {
		name: "CustomForm",
		props: {
			dicts: {
				type: Object,
				default: function () {
					return {}
				}
			},
			isAdd: {
				type: Boolean,
				default: true,
			},
			selectRow: {
				type: Object,
				default: function () {
					return {}
				},
			},
			onFresh: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				title: '',
				saveData: {},
				open: false
			}
		},
		watch: {
			onFresh: function (value) {
				this.open = value;
				if (value) {
					this.resetData();
				}
			}
		},
		mounted() {
		},
		methods: {
			async resetData() {
				if (this.isAdd){
					this.title = this.translate('FROM.ADD_MODULE', {module: 'MODULE.CONFIG'});
					this.saveData = {};
				}else {
					this.saveData = this.selectRow;
					this.isAdd = false;
					this.title = this.translate('FROM.UPDATE_MODULE', {module: 'Config'});
				}
			},
			async submitForm() {
				let result = (this.isAdd) ? (await BasicApi.config.save(this.saveData)) : (await BasicApi.config.edit(this.saveData.id, this.saveData));
				if (result.success) {
					this.Message.success(this.translate(this.isAdd ? 'FROM.ADD_SUCCESS' : 'FROM.UPDATE_SUCCESS'));
					this.commitOnClose();
				}
			},
			cancel() {
				this.saveData = {};
				this.commitOnClose();
			},
			commitOnClose() {
				this.$emit('on-close');
			}
		}
	};
</script>

<style scoped>

</style>
